import React, { useState } from "react"
import { Col, Row } from "react-bootstrap";

// Styles
import "./FilterContent.scss"

// Component
import Divider from "components/Divider";
import FallbackComponent from "components/FallbackComponent";

const defaultLayout = {
    lg: 3,
    md: 6,
    sm: 12
};

const highlightCardLayout = {
    lg: 12,
    md: 12,
    sm: 12
}

const FilterContent = ( filterContentData ) => {

    const { categories = [], content = [], CardComponent = FallbackComponent, layout = defaultLayout, showFeaturedCard = false, selectedCategory, setSelectedCategory } = filterContentData;

    //console.log("filterContentData", filterContentData);

    var category_lenth_get = categories?.length > 0 && categories.filter(item => item?.news_insights?.length > 0);    

    return (
        <div className="filter-content-wrapper">
            {/* <div className="filter-content-header">
                <div
                    key={ -1 }
                    className={ `filter-content-header-item ${selectedCategory === "All" ? "selected" : ""}` }
                >
                    <span
                        className="header-item-content"
                        onClick={ () => setSelectedCategory("All") }
                    >
                        <span>All</span>
                        <span className="count">
                            { content?.length }
                        </span>
                    </span>
                    <Divider delimiter="|" />
                </div>

                {
                    categories?.length > 0 && categories.filter(item => item?.news_insights?.length > 0).map( ( categoriesItem, categoriesIndex ) => {
                        
                        return (
                            <div
                                key={ categoriesIndex }
                                className={ `filter-content-header-item ${ selectedCategory?.Title === categoriesItem.Title ? 'selected' : '' }` }
                            >
                                <span
                                    className="header-item-content"
                                    onClick={ () => setSelectedCategory( categoriesItem ) }
                                >
                                    <span>
                                        { categoriesItem.Title }
                                    </span>
                                    <span className="count">
                                        { categoriesItem?.news_insights?.length }
                                    </span>
                                </span>
                                {
                                    ( category_lenth_get.length - 1 !== categoriesIndex ) && (
                                        <Divider delimiter="|" />
                                    )
                                }
                            </div>
                        )
                    } )
                }
            </div> */}
            <div className="filter-content-body">
                <Row>
                    {
                        // content
                        //     .filter( contentItem => ( selectedCategory.name === "All" || contentItem.Tags.includes( selectedCategory.name ) ) )
                        //     .map( ( contentItem, contentIndex ) => {
                        content?.length > 0 && content?.map( ( contentItem, contentIndex ) => {
                            const isHighlightCard = ( contentIndex === 0 );
                            const cardLayout = showFeaturedCard && isHighlightCard ? highlightCardLayout : layout;
                            return (
                                <Col
                                    key={ contentIndex }
                                    { ...cardLayout }
                                >
                                    <CardComponent
                                        { ...contentItem }
                                        isHighlightCard={ isHighlightCard }
                                    />
                                </Col>
                            )
                        } )
                    }
                </Row>
            </div>
        </div >
    )
}

export default FilterContent
