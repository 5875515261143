import React, { useRef } from "react"
import { Col, Container, Row, Card } from "react-bootstrap"
import Slider from "react-slick"
import { Link } from "@reach/router"
// Styles
import "../AreaGuides/AreaGuides.scss"
import "./Job.scss"

// Components
import multiContentSlider from "../../utilities/multiContentSlider"
import NavigationArrow from "../NavigationArrow"

import SectionWrapper from "../SectionWrapper"
import SectionHeader from "../SectionHeader"
import { ImageModule } from "../../modules/Image_Module"
import JobArrow from "../../images/job_arrow.svg"

// Components
import { CustomLinks } from "../common/menuUtils"

import { GetCareers } from "../../queries/common_use_query"

const Careers = props => {
  const { loading, error, data } = GetCareers()
  const job_vacancies_class = props?.extraClass ? props?.extraClass : ""
  const sliderRef = useRef()

  const sectionHeaderData = {
    Title: props.Module.Heading,
  }

  const settings = {
    ...multiContentSlider,
    infinite: data && data?.careers?.length > 3 ? true : false,
    nextArrow: <NavigationArrow type="left" sliderRef={sliderRef} />,
    prevArrow: <NavigationArrow type="right" sliderRef={sliderRef} />,
  }

  return (
    <SectionWrapper
      className={`layout-01-wrapper area-guides-wrapper ${job_vacancies_class}`}
    >
      <Container>
        <Row>
          <Col>
            <div className="layout-01-header-section">
              <SectionHeader sectionHeaderData={sectionHeaderData} />
            </div>
          </Col>
        </Row>

        {data && data?.careers?.length > 0 && (
          <div className="multi-content-slider-wrapper job-details">
            <Slider {...settings} ref={sliderRef}>
              {data?.careers?.map((sliderDataItem, sliderDataIndex) => {
                console.log("sliderDataItem::", sliderDataItem)

                var job_link =
                  "/" + CustomLinks.careers + "/" + sliderDataItem.URL + "/"
                return (
                  // <div
                  //     key={ sliderDataIndex }
                  //     className="slider-image-wrapper"
                  // >
                  //     <div className="single-area-card-wrapper">
                  //         <Card>
                  //             <div className="card-container-image">
                  //                 <Link to={job_link}>
                  //                     <ImageModule ImageSrc={ sliderDataItem?.Title_Image } classNames={"card-img-top"} />
                  //                 </Link>
                  //             </div>
                  //             <Card.Body>
                  //                 <Card.Title>
                  //                     <Link to={job_link}>{ sliderDataItem.Title }</Link>
                  //                 </Card.Title>
                  //                 <Card.Text className="card-content">
                  //                     <b>Location:</b> { sliderDataItem.Location }
                  //                 </Card.Text>

                  //                 <Link to={job_link} className="job_arw"><img  src={JobArrow} alt={ sliderDataItem.Title } /></Link>
                  //             </Card.Body>
                  //         </Card>
                  //     </div>
                  // </div>
                  <Card className="job_card">
                    <Card.Img
                      variant="top"
                      src={sliderDataItem?.Title_Image?.url}
                      alt={sliderDataItem.Title}
                    />
                    <Card.Body>
                      <Row>
                        <Col sm={10}>
                          <Card.Title>
                            <Link to={job_link}>{sliderDataItem?.Title}</Link>
                            <p className="card-content d-block d-md-block">
                              <strong>Location:</strong>{" "}
                              {sliderDataItem?.Location}
                            </p>
                          </Card.Title>
                        </Col>
                        <Col sm={2}>
                          <Card.Text className="card-content">
                            <Link
                              to={job_link}
                              className="job_arw align-middle"
                            >
                              <img
                                className="d-block align-middle"
                                src={JobArrow}
                                alt={sliderDataItem.Title}
                              />
                            </Link>
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              })}
            </Slider>
          </div>
        )}
      </Container>
    </SectionWrapper>
  )
}

export default Careers
